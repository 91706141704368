import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getFirestore, doc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// import 'dotenv';

// import firebase from 'firebase/app';
import "firebase/storage";

// TODO: switch for other keys
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// console.log(process.env);

const firebaseConfig = {
  apiKey: process.env.EXPO_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.EXPO_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.EXPO_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.EXPO_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
// const app = initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
// const storage = firebase.storage();

export const db = getFirestore();
const storage = firebase.storage();

// export const storage = getStorage();
export { doc };

export { firebase, auth, firestore, storage };

// export default app;
export default firebaseConfig;
